:root {
  --color-dark: #06000e;
  --color-dark-rgb: 6, 0, 14;
  --color-dark-accent: #110922;
  --color-light: #f3eaff;
  --color-light-rgb: 243, 234, 255;
  --color-gray-lightest: rgba(var(--color-light-rgb), 0.16);
  --color-error: #d92b2b;
  --color-warging: #fda965;
  --color-success: #24c775;
  --color-primary: #9865fd;
  --container-gap: 24px;
}

body {
  background: var(--color-dark);
}

#root {
  height: 100%;
}

.ant-app {
  height: 100%;
}
