.container {
  height: 100%;
}

.wrapper {
  height: 100%;
  align-items: flex-start;
}

.aside,
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--container-gap);
  padding-bottom: var(--container-gap);
}

.aside {
  background: var(--color-primary);
}

.logo {
  max-width: 120px;
}

@media (min-width: 768px) {
  .wrapper {
    align-items: stretch;
  }

  .logo {
    max-width: 240px;
  }
}
