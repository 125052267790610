.toolbar {
  display: flex;
  align-items: center;
  background: rgba(var(--color-dark-rgb), 0.56);
  border-radius: 2rem;
  padding-right: calc(var(--container-gap) / 2);
  padding-left: calc(var(--container-gap) / 2);
}

.toolbar :global(.anticon) {
  font-size: 1.2rem;
  color: var(--color-light);
  padding: calc(var(--container-gap) / 2);
  cursor: pointer;
  transition: opacity 0.2s;
}

.toolbar :global(.anticon):hover {
  opacity: 0.76;
}

.toolbar :global(.anticon)[disabled] {
  opacity: 0.32;
  cursor: not-allowed;
}

.toolbar__separator {
  font-size: 1.4em;
  padding: calc(var(--container-gap) / 2);
  opacity: 0.32;
  user-select: none;
}
