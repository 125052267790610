.root {
  background: var(--color-dark);
  z-index: 9999;
  transition: all 0.5s ease-in-out;
}

.logo {
  width: 100% !important;
  height: auto !important;
  max-width: 120px;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}
