.root {
  --header-height: 64px;
  --sidebar-width: 200px;
  --toggle-size: 40px;
  min-height: 100%;
}

.header {
  border-bottom: 1px solid var(--color-gray-lightest);
  position: sticky;
  top: 0;
  z-index: 1;
}

.header__container {
  display: flex;
  align-items: center;
}

.header__menu {
  background: none;
  border-bottom: none;
  margin-left: auto;
}

.header__menu__item {
  padding-inline: 0 !important;
}

.header__menu__item::after {
  inset-inline: 0 !important;
}

.sidebar {
  border-right: 1px solid var(--color-gray-lightest);
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.sidebar :global(.ant-layout-sider-zero-width-trigger) {
  margin-right: -1px;
  top: calc(var(--header-height) / 2 - var(--toggle-size) / 2);
}

.sidebar__logo {
  display: flex;
  align-items: center;
  height: var(--header-height);
  padding-right: calc(var(--container-gap) / 2);
  padding-left: calc(var(--container-gap) / 2);
}

.sidebar__logo__icon {
  width: 100%;
  max-width: 120px;
}

.sidebar__menu {
  background: none;
  border-inline-end: none !important;
  padding-top: var(--container-gap);
  padding-bottom: var(--container-gap);
}

.sidebar__menu__item {
  padding-right: calc(var(--container-gap) / 2) !important;
  padding-left: calc(var(--container-gap) / 2) !important;
}

.content__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  padding-top: var(--container-gap);
  padding-bottom: var(--container-gap);
}

.footer {
  border-top: 1px solid var(--color-gray-lightest);
  position: sticky;
  bottom: 0;
}

.footer__container {
  display: flex;
  align-items: center;
  min-height: var(--header-height);
  padding-top: calc(var(--container-gap) / 2);
  padding-bottom: calc(var(--container-gap) / 2);
}

.title {
  margin-bottom: var(--container-gap) !important;
}

@media (min-width: 992px) {
  .root {
    padding-left: var(--sidebar-width);
  }
}
