
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: white;
    opacity: 1;
    background: rgba(0, 0, 0, 0.9);
}

.swiper-pagination-bullet-active {
    color: black;
    background: white;
}

.swiper-button-next {
    color: #ffffff;
    position: absolute;
    top: 90%;

}

.swiper-button-prev {
    color: #ffffff;
    position: absolute;
    top: 90%;
}

