.card__image {
  padding-bottom: 100%;
  position: relative;
}

.card__image > img {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.preview__toolbar svg {
  color: var(--color-primary);
}
