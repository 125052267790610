.table {
  white-space: nowrap;
}

.table__reason {
  width: 100%;
  max-width: 32em;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table__user {
  max-width: 16em;
}

.table__type {
  color: #fda965;
}
